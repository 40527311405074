import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { useSnackbar } from 'notistack';

import { COUNTRY_ID_OBJ } from 'app/Auth/routes/Register/configs';
import {
  courseSubsStatuses,
  findCourseFirstLesson,
  findCourseFirstLessonAndRedirect,
  getError,
} from 'utils/appHelpers';
import { formatSubscription } from 'utils/formatHelpers';
import { Api } from 'utils/connectors';
import { getFromStore } from 'utils/storeHelpers';

import AuthorsList from './components/AuthorsList';
import LessonsList from './components/LessonsList';
import { getCourses } from '../Courses/actions';
import BackButton from 'shared/BackButton';
import { IconKey, IconLock } from 'shared/components/Icons';
import TagsViewBlock from 'shared/components/TagsViewBlock';
import SubscriptionModal from 'shared/components/SubscriptionModal';
import ProgressBar from 'shared/components/ProgressBar';
import ButtonLoading from 'shared/components/ButtonLoading';
import Loading from 'shared/components/Loading';
import { API_RESPONSE_STATUS_CODES } from 'configs/constants';

const subsTypes = {
  1: 'No Key Required',
  2: 'Paid',
  3: (
    <>
      <IconKey /> Key Required
    </>
  ),
};

const LIVE_COURSE_IDS_OBJ = {
  us: [29, 6],
  aus: [32],
};

const CourseDetails = ({ history, match, getCourses }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id, action } = match.params;
  const courses = useSelector(store => store.courses);
  const course = courses.find(item => item.id === Number(id));
  const [modalData, setModalData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [fetch, setFetch] = useState(null);
  const [isCheckingCourseAccess, setIsCheckingCourseAccess] = useState(false);
  const [isTestCompleted, setIsTestCompleted] = useState(getFromStore('connectionAndDeviceTested'));

  const radiologist8HourCourse =
    courses && courses?.find(course => course?.name?.startsWith('Radiologists: 8-hour'));
  const radiologist8HourCourseId = radiologist8HourCourse?.id;

  const profile = useSelector(state => state.account);

  const openWarningModal = message => {
    confirmAlert({
      message,
      buttons: [
        {
          label: 'Close',
        },
      ],
    });
  };

  const fetchData = async isEnroll => {
    try {
      const subs = course.subscriptionPlans && course.subscriptionPlans[0];
      const { data } = await Api.get(`subscription/getsubscription/${subs.subscriptionId}`);
      setSubscription(formatSubscription(data.data));
      if (isEnroll) {
        findCourseFirstLessonAndRedirect(course, history);
      }
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    }
  };

  const checkAccess = async () => {
    try {
      setIsCheckingCourseAccess(true);
      const res = await Api.get(`/courses/check-to-enroll/${id}`);
      if (
        res?.status === API_RESPONSE_STATUS_CODES.Success ||
        res?.status === API_RESPONSE_STATUS_CODES.CreateUpdate
      ) {
        const needToGetCourses = res?.status === API_RESPONSE_STATUS_CODES.CreateUpdate;
        findCourseFirstLessonAndRedirect(course, history, needToGetCourses);
      }
    } catch (err) {
      return;
    } finally {
      setIsCheckingCourseAccess(false);
    }
  };

  const onUnSubscribeSubscription = async () => {
    try {
      setFetch(true);
      await Api.post('/subscription/unsubscribe', { subscriptionId: subscription.id });
      await getCourses();
      await fetchData();
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      setFetch(false);
    }
  };

  const checkIsTestCompleted = event => {
    const isDone = getFromStore('connectionAndDeviceTested');
    if (isDone) setIsTestCompleted(true);
    else setTimeout(checkIsTestCompleted, 1000);
  };

  useEffect(() => {
    if (LIVE_COURSE_IDS_OBJ.aus.includes(Number(id))) {
      if (profile.countryId === COUNTRY_ID_OBJ.us) {
        openWarningModal(
          'Please note: this course is intended for our friends in Australia and New Zealand only.',
        );
      }
    } else if (LIVE_COURSE_IDS_OBJ.us.includes(Number(id))) {
      if (profile.countryId === COUNTRY_ID_OBJ.aus) {
        openWarningModal(
          'Please note: there is a specific course intended for our friends in United States.',
        );
      }
    }

    if (course) {
      fetchData();
      if (course.userCourse) {
        findCourseFirstLessonAndRedirect(course, history);
      } else {
        checkAccess();
      }
    }

    //eslint-disable-next-line
    checkIsTestCompleted();
  }, [id]);

  const isEventType = false; //course.courseType === 1;

  const isPending =
    subscription &&
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'pending');
  const isDeclined =
    subscription &&
    (!subscription.userSubs ||
      (subscription.userSubs &&
        courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'declined')));
  const isAccepted =
    subscription &&
    subscription.userSubs &&
    courseSubsStatuses(subscription.userSubs.userSubscriptionStatus, 'active');

  if (modalData === null && isDeclined && !isEventType && action === 'enroll' && isTestCompleted) {
    setModalData(subscription);
  }

  if (!subscription || isCheckingCourseAccess) return <Loading classView='mt-5' />;

  return (
    <div className='course-view flex-1 d-flex flex-column position-relative'>
      <div className='header-section align-items-center d-flex w-100 px-4 header-section justify-content-between'>
        <div className='d-flex'>
          <BackButton history={history} route='/allcourses' />
          <div className='course-name border-0 pr-3'>{course.name}</div>
        </div>
      </div>
      <div className='py-3 d-flex'>
        <div className='col-8'>
          <div className='px-3'>
            <h5 className='py-3 color-blue d-flex align-items-center'>
              <IconLock className='mr-2' />
              {course.name}*
            </h5>
            {course.description && (
              <div className='text-muted mb-5'>
                <span className='d-block mb-2 text-lg'>About course</span>
                <span className='d-block white-space-pre'>{course.description}</span>
              </div>
            )}
            {course.authors && course.authors.length > 0 && (
              <AuthorsList data={course.authors} history={history} />
            )}
            <p className='text-secondary'>Lessons</p>
            {course.lessons && course.lessons.length > 0 && <LessonsList data={course.lessons} />}
          </div>
        </div>
        <div className='col-4 py-3'>
          <div className='plan-info-box'>
            {!!course?.image && <img src={course.image} alt='course' />}
            <h3 className='mt-3 mb-2 font-weight-light font-size-15'>
              <b>{course.name}*</b>
            </h3>
            <div className='d-flex justify-content-between'>
              <span>Registration Code</span>
              <span>{subsTypes[subscription.keyRequired ? 3 : subscription.type] || '-'}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Parts</span>
              <span>{course.lessons ? course.lessons.length : '0'} parts</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>Faculty</span>
              <span>{course.authors.length} Faculty</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span> Validity Period</span>
              <span>{subscription.expirable ? `${subscription.days} days` : 'No Expiration'}</span>
            </div>
            {isDeclined && !isEventType && (
              <div className='d-flex justify-content-center'>
                <button
                  className='btn subscribe-btn-block mt-3'
                  onClick={() => setModalData(subscription)}
                >
                  Enroll
                </button>
              </div>
            )}
            {isAccepted && (
              <>
                <div className='d-flex flex-column pt-3'>
                  <div className='d-flex justify-content-between pb-2'>
                    <span className='mr-4'>
                      <b className='mr-2'>Start to:</b>
                      {subscription.userSubs.startDate.substr(0, 10)}
                    </span>
                    {!!subscription.expirable && (
                      <span>
                        <b className='mr-2'>End to:</b>
                        {subscription.userSubs.endDate.substr(0, 10)}
                      </span>
                    )}
                  </div>

                  <div className='d-flex align-items-center justify-content-between'>
                    <span className='mr-5 text-nowrap'>
                      Progress {subscription.userSubs.progress || 0}%
                    </span>
                    <ProgressBar progress={subscription.userSubs.progress || 0} />
                  </div>
                </div>
                {!isEventType && (
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn mt-4 text-danger'
                      onClick={onUnSubscribeSubscription}
                      disabled={fetch || subscription.isSystem}
                    >
                      {fetch ? <ButtonLoading /> : 'Deactivate'}
                    </button>
                  </div>
                )}
              </>
            )}
            {isPending && <div className='text-center text-secondary small mt-4'>Request Sent</div>}
            <p className='mt-3 mb-0 enroll-text'>
              * To get access to this course you first need to enroll
            </p>
          </div>
          <TagsViewBlock tags={course.courseTags} />
        </div>
      </div>
      {modalData && (
        <SubscriptionModal
          data={modalData}
          onModalClose={() => setModalData(false)}
          onSuccess={fetchData}
          isFellowCourse={course?.name?.startsWith('Breast Fellow')}
          radiologist8HourCourseId={radiologist8HourCourseId}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  courses: state.courses,
});

const mapDispatchToProps = {
  getCourses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseDetails);
